import React from "react";

function About() {
  return (
    <div className="relative w-full h-[calc(100vh-4rem)] bg-white flex justify-center">
      <div className="container mx-auto p-4 w-full max-w-md sm:max-w-2xl lg:max-w-3xl">
        <p>
          Pixel Combine is an independent game development effort founded in
          2023 by a developer dedicated to creating immersive experiences that
          bring people together. Specializing in VR/MR development, Pixel
          Combine aims to bring joy and entertainment to the gaming community
          with innovative titles. By continually pushing the boundaries of game
          development, the studio seeks to redefine what's possible in the world
          of interactive entertainment.
          <br />
        </p>
      </div>
    </div>
  );
}

export default About;

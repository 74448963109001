import React, { useState, useEffect } from "react";
import * as THREE from "three";
import ThreeScene from "../components/Canvas";

function Home() {
  const [currentGeometryIndex, setCurrentGeometryIndex] = useState(0);

  const geometries = [
    { geometry: new THREE.BoxGeometry(), color: 0xff6060 },
    { geometry: new THREE.SphereGeometry(1, 8, 8), color: 0x60ff60 },
    { geometry: new THREE.PlaneGeometry(2, 2), color: 0x6060ff },
    { geometry: new THREE.CylinderGeometry(1, 1, 2, 8), color: 0x0fff00 },
    { geometry: new THREE.ConeGeometry(1, 2, 8), color: 0x8888ff },
    { geometry: new THREE.TorusGeometry(1, 0.4, 8, 8), color: 0x00ffff },
    { geometry: new THREE.TorusKnotGeometry(1, 0.4, 16, 16), color: 0xffdd88 },
    { geometry: new THREE.CircleGeometry(1, 8), color: 0xbb88ff },
    { geometry: new THREE.DodecahedronGeometry(1), color: 0x88ddff },
    { geometry: new THREE.IcosahedronGeometry(1), color: 0xff88dd },
    { geometry: new THREE.OctahedronGeometry(1), color: 0xaaff44 },
    { geometry: new THREE.TetrahedronGeometry(1), color: 0xff6666 },
    { geometry: new THREE.RingGeometry(0.5, 1, 8), color: 0x4444ff },
    // {
    //   geometry: new THREE.LatheGeometry(
    //     [
    //       new THREE.Vector2(0, 0),
    //       new THREE.Vector2(1, 1),
    //       new THREE.Vector2(0.5, 1.5),
    //     ],
    //     8
    //   ),
    //   color: 0xff44ff,
    // },
  ];

  const handleClick = () => {
    setCurrentGeometryIndex((prevIndex) => (prevIndex + 1) % geometries.length);
  };

  useEffect(() => {
    // Randomize the index when the component mounts
    const randomIndex = Math.floor(Math.random() * geometries.length);
    setCurrentGeometryIndex(randomIndex);
  }, [geometries.length]); // Only run when the geometries array length changes

  return (
    <div className="relative w-full h-[calc(100vh-4rem)] bg-black flex items-center justify-center">
      <ThreeScene geometry={geometries[currentGeometryIndex]} />
      <div className="absolute inset-0 flex items-center justify-center p-4 md:p-8">
        <span
          style={{ cursor: "pointer", whiteSpace: "nowrap" }}
          className="text-lg md:text-2xl font-dot-gothic-16 text-black select-none text-center"
          onClick={handleClick}
        >
          Craft&nbsp;Experience
          <br />
          Build&nbsp;Connection
        </span>
      </div>
    </div>
  );
}

export default Home;

import React from "react";

function Huetopia() {
  return (
    <div className="relative w-full h-[calc(100vh-4rem)] bg-white flex justify-center">
      <div className="container mx-auto p-4 w-full max-w-md sm:max-w-2xl lg:max-w-3xl">
        <p>Huetopia explanation here.</p>
      </div>
    </div>
  );
}

export default Huetopia;

import React from "react";

function Privacy() {
  return (
    <div className="container mx-auto p-4 w-full max-w-md sm:max-w-2xl lg:max-w-3xl">
      <h1 className="text-2xl font-bold mb-4">Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> October 1st, 2024
      </p>
      <br />
      <h2 className="font-bold">1. Introduction</h2>
      <p>
        At Pixel Combine ("we," "us," "our"), we value the privacy of our users
        ("you," "your"). This Privacy Policy describes how we collect, use,
        process, and share your data when you use our app, <i>Huetopia</i>, on
        Meta Platforms Technologies devices, including Meta Quest. We are
        committed to ensuring that your privacy is protected and that your User
        Data is handled in compliance with Meta Platforms’ Developer Data Use
        Policy, Quest Virtual Reality Checks (VRCs), and applicable laws and
        regulations.
      </p>
      <br />
      <h2 className="font-bold">2. Data We Process</h2>
      <p>
        We collect and process the following types of User Data while you
        interact with our app:
      </p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Contact information (name,
          email, etc.)
        </li>
        <li>
          <strong>Usage Data:</strong> Data about your account and game progress
          (in-app activity, interaction history, etc.)
        </li>
        <li>
          <strong>Device Information:</strong> Your IP address and device
          identifiers
        </li>
        {/* <li>
          <strong>Meta Platforms Technologies User Data:</strong> [fill out]
          (specific types of data retrieved from Meta platforms)
        </li> */}
      </ul>
      <p>
        Please note, we only collect and process data that is necessary for the
        functionality of the app.
      </p>
      <br />
      <h2 className="font-bold">3. How We Process Your Data</h2>
      <p>We process your data in the following ways:</p>
      <ul>
        <li>
          <strong>Data Collection:</strong> We collect User Data directly from
          your device or via Meta Platforms API when you use our app.
        </li>
        <li>
          <strong>Data Storage:</strong> User Data is securely stored on Unity
          Cloud.
        </li>
        {/* <li>
          <strong>Data Sharing:</strong> We do not share your data with any
          third parties, except for [fill out: trusted third-party service
          providers, partners, etc., if applicable].
        </li> */}
        <li>
          <strong>Data Security:</strong> We use appropriate security measures
          to protect your data from unauthorized access, including HTTPS
          encryption.
        </li>
      </ul>
      <br />
      <h2 className="font-bold">4. Purposes of Data Processing</h2>
      <p>We process your User Data for the following purposes:</p>
      <ul>
        <li>
          <strong>To Provide App Functionality:</strong> Your data is necessary
          for the smooth operation and performance of the app.
        </li>
        <li>
          <strong>To Improve User Experience:</strong> We may use your data to
          enhance features, fix bugs, or optimize performance.
        </li>
        <li>
          <strong>Compliance and Legal Obligations:</strong> Your data may be
          processed to comply with legal obligations or requests from law
          enforcement.
        </li>
      </ul>

      <br />
      <h2 className="font-bold">5. How to Request Deletion of Your Data</h2>
      <p>
        You have the right to request the deletion of your personal data. To
        make such a request, please contact us at{" "}
        <i>contact@pixelcombine.com</i>. Upon verification, we will delete your
        data in accordance with applicable laws and regulations.
      </p>

      <br />
      <h2 className="font-bold">6. Updates to This Privacy Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page with an updated effective date.
      </p>

      <br />
      <h2 className="font-bold">7. Contact Us</h2>
      <p>
        If you have any questions regarding this Privacy Policy, or if you wish
        to exercise your rights regarding your data, please contact us at:
      </p>
      <p>
        Pixel Combine
        <br />
        contact@pixelcombine.com
        <br />
        200-4, Godeokjungang-ro Pyeongtaek-si, Gyeonggi-do, Republic of Korea
        <br />
        <br />
      </p>
      <p>
        This policy is protected by an HTTPS connection certified by a trusted
        Certificate Authority. For more information on website certificate
        authorities, please visit{" "}
        <a href="https://https.cio.gov/certificates/">
          https://https.cio.gov/certificates/
        </a>
        .
      </p>
    </div>
  );
}

export default Privacy;
